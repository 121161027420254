<template>
  <div class="container">

      <div class="row">

          <div class="col-lg-12">
              <v-breadcrumbs :items="breadcumbs">
                  <template v-slot:item="{ item }">
                      <v-breadcrumbs-item
                          :to="item.href"
                          class="text-subtitle-2 crumb-item"
                          :disabled="item.disabled"
                          exact
                      >
                          {{ item.text }}
                      </v-breadcrumbs-item>
                  </template>
              </v-breadcrumbs>
              
          </div>

          <div class="col-6 col-lg-2">
              <div class="card text-white primary elevation-5">
                  <div class="card-body">
                      <p class="card-title">Order {{ last2MonthThisYear ? last2MonthThisYear : 'Loading...' }}</p>
                      <!-- <p class="">{{ orderMonthM2 ? orderMonthM2 : 'Loading...' }} IDR</p> -->
                      <p class="">{{orderMonthM2 ? new Intl.NumberFormat('id-ID', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(orderMonthM2) : 'Loading...'}} IDR</p>
                      <p class="">{{ countOrderMonthM2 ? countOrderMonthM2 : 'Loading...' }} Orders</p>
                  </div>
                      <div id="divider" style="background-color:#ffffff; height: 1px; width:100%;"></div>
                  <div class="card-body">
                      <p class="card-title">Order {{ last2MonthLastYear ? last2MonthLastYear : 'Loading...' }}</p>
                      <!-- <p class="">{{ orderMonthM2YearM1 ? orderMonthM2YearM1 : 'Loading...' }} IDR</p> -->
                      <p class="">{{orderMonthM2YearM1 ? new Intl.NumberFormat('id-ID', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(orderMonthM2YearM1) : 'Loading...'}} IDR</p>
                      <p class="">{{ countOrderMonthM2YearM1 ? countOrderMonthM2YearM1 : 'Loading...' }} Orders</p>
                  </div>
              </div>
          </div>

          <div class="col-6 col-lg-2">
              <div class="card text-white primary elevation-5">
                  <div class="card-body">
                      <p class="card-title">Order {{ lastMonthThisYear ? lastMonthThisYear : 'Loading...' }}</p>
                      <!-- <p class="">{{ orderMonthM1 ? orderMonthM1 : 'Loading...' }} IDR</p> -->
                      <p class="">{{orderMonthM1 ? new Intl.NumberFormat('id-ID', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(orderMonthM1) : 'Loading...'}} IDR</p>
                      <p class="">{{ countOrderMonthM1 ? countOrderMonthM1 : 'Loading...' }} Orders</p>
                  </div>
                      <div id="divider" style="background-color:#ffffff; height: 1px; width:100%;"></div>
                  <div class="card-body">
                      <p class="card-title">Order {{ lastMonthLastYear ? lastMonthLastYear : 'Loading...' }}</p>
                      <!-- <p class="">{{ orderMonthM1YearM1 ? orderMonthM1YearM1 : 'Loading...' }} IDR</p> -->
                      <p class="">{{orderMonthM1YearM1 ? new Intl.NumberFormat('id-ID', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(orderMonthM1YearM1) : 'Loading...'}} IDR</p>
                      <p class="">{{ countOrderMonthM1YearM1 ? countOrderMonthM1YearM1 : 'Loading...' }} Orders</p>
                  </div>
              </div>
          </div>

          <div class="col-6 col-lg-2">
              <div class="card text-white primary elevation-5">
                  <div class="card-body">
                      <p class="card-title">Order {{ thisMonthThisYear ? thisMonthThisYear : 'Loading...' }}</p>
                      <!-- <p class="">{{ orderNow ? orderNow : 'Loading...' }} IDR</p> -->
                      <p class="">{{orderNow ? new Intl.NumberFormat('id-ID', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(orderNow) : 'Loading...'}} IDR</p>
                      <p class="">{{ countOrderNow ? countOrderNow : 'Loading...' }} Orders</p>
                  </div>
                      <div id="divider" style="background-color:#ffffff; height: 1px; width:100%;"></div>
                  <div class="card-body">
                      <p class="card-title">Order {{ thisMonthLastYear ? thisMonthLastYear : 'Loading...' }}</p>
                      <!-- <p class="">{{ orderNowYearM1 ? orderNowYearM1 : 'Loading...' }} IDR</p> -->
                      <p class="">{{orderNowYearM1 ? new Intl.NumberFormat('id-ID', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(orderNowYearM1) : 'Loading...'}} IDR</p>
                      <p class="">{{ countOrderNowYearM1 ? countOrderNowYearM1 : 'Loading...' }} Orders</p>
                  </div>
              </div>
          </div>

          <div class="col-6 col-lg-2">
            <div class="card text-white primary elevation-5">
                  <div class="card-body">
                      <p class="card-title">Visitor Info {{ last2MonthThisYear ? last2MonthThisYear : 'Loading...' }}</p>
                      <p class="">Total : {{ visitTotalMonthM2 ? visitTotalMonthM2 : 'Loading...' }} Persons</p>
                      <p class="">Aplikator : {{ visitAplikatorMonthM2 ? visitAplikatorMonthM2 : 'Loading...' }} Persons</p>
                      <p class="">Umum : {{ visitUmumMonthM2 ? visitUmumMonthM2 : 'Loading...' }} Persons</p>
                      <p class="">Toko : {{ visitTokoMonthM2 ? visitTokoMonthM2 : 'Loading...' }} Persons</p>
                    </div>
                      <div id="divider" style="background-color:#ffffff; height: 1px; width:100%;"></div>
                  <div class="card-body">
                      <p class="">Online : {{ visitOnlineMonthM2 ? visitOnlineMonthM2 : 'Loading...' }} Persons</p>
                      <p class="">Offline : {{ visitOfflineMonthM2 ? visitOfflineMonthM2 : 'Loading...' }} Persons</p>
                      <!-- <div class="flex-container">
                          <p>New Customer</p>
                          <p><v-chip class="ma-1" small color="green" dark @click="newCustomerDetails(monthNum, year)">{{ newCustomerThisMonth ? newCustomerThisMonth : 'Loading...' }}</v-chip></p>
                      </div>
                      <div class="flex-container">
                          <p>Active Outlet</p>
                          <p><v-chip class="ma-1" small color="green" dark @click="newOutletDetails(monthNum, year)">{{ activeOutletThisMonth ? activeOutletThisMonth : 'Loading...' }}</v-chip></p>
                      </div>
                      <div class="flex-container">
                          <p>Total Customer Visit</p>
                          <p><v-chip class="ma-1" small color="green" dark @click="newVisitDetails(monthNum, year)">{{ customerVisitThisMonth ? customerVisitThisMonth : 'Loading...' }}</v-chip></p>
                      </div> -->
                  </div>
              </div>
          </div>

          <div class="col-6 col-lg-2">
              <div class="card text-white primary elevation-5">
                  <div class="card-body">
                      <!-- <p class="card-title">Outlet Info {{ subMonthYear ? subMonthYear : 'Loading...' }}</p> -->
                      <p class="card-title">Visitor Info {{ lastMonthThisYear ? lastMonthThisYear : 'Loading...'}}</p>
                      <p class="">Total : {{ visitTotalMonthM1 ? visitTotalMonthM1 : 'Loading...' }} Persons</p>
                      <p class="">Aplikator : {{ visitAplikatorMonthM1 ? visitAplikatorMonthM1 : 'Loading...' }} Persons</p>
                      <p class="">Umum : {{ visitUmumMonthM1 ? visitUmumMonthM1 : 'Loading...' }} Persons</p>
                      <p class="">Toko : {{ visitTokoMonthM1 ? visitTokoMonthM1 : 'Loading...' }} Persons</p>
                    </div>
                      <div id="divider" style="background-color:#ffffff; height: 1px; width:100%;"></div>
                  <div class="card-body">
                      <p class="">Online : {{ visitOnlineMonthM1 ? visitOnlineMonthM1 : 'Loading...' }} Persons</p>
                      <p class="">Offline : {{ visitOfflineMonthM1 ? visitOfflineMonthM1 : 'Loading...' }} Persons</p>
                      <!-- <div class="flex-container">
                          <p>New Customer</p>
                          <p><v-chip class="ma-1" small color="green" dark @click="newCustomerDetails(subMonthNum, year)">{{ newCustomerSubMonth ? newCustomerSubMonth : 'Loading...' }}</v-chip></p>
                      </div>
                      <div class="flex-container">
                          <p>Active Outlet</p>
                          <p><v-chip class="ma-1" small color="green" dark @click="newOutletDetails(subMonthNum, year)">{{ activeOutletSubMonth ? activeOutletSubMonth : 'Loading...' }}</v-chip></p>
                      </div>
                      <div class="flex-container">
                          <p>Total Customer Visit</p>
                          <p><v-chip class="ma-1" small color="green" dark @click="newVisitDetails(subMonthNum, year)">{{ customerVisitSubMonth ? customerVisitSubMonth : 'Loading...' }}</v-chip></p>
                      </div> -->
                  </div>
              </div>
              
          </div>

          <div class="col-6 col-lg-2">
              <div class="card text-white primary elevation-5">
                  <div class="card-body">
                      <p class="card-title">Visitor Info {{ thisMonthThisYear ? thisMonthThisYear : 'Loading...' }}</p>
                      <p class="">Total : {{ visitTotalNow ? visitTotalNow : 'Loading...' }} Persons</p>
                      <p class="">Aplikator : {{ visitAplikatorNow ? visitAplikatorNow : 'Loading...' }} Persons</p>
                      <p class="">Umum : {{ visitUmumNow ? visitUmumNow : 'Loading...' }} Persons</p>
                      <p class="">Toko : {{ visitTokoNow ? visitTokoNow : 'Loading...' }} Persons</p>
                    </div>
                      <div id="divider" style="background-color:#ffffff; height: 1px; width:100%;"></div>
                  <div class="card-body">
                      <p class="">Online : {{ visitOnlineNow ? visitOnlineNow : 'Loading...' }} Persons</p>
                      <p class="">Offline : {{ visitOfflineNow ? visitOfflineNow : 'Loading...' }} Persons</p>
                      <!-- <div class="flex-container">
                          <p>New Customer</p>
                          <p><v-chip class="ma-1" small color="green" dark @click="newCustomerDetails(monthNum, year)">{{ newCustomerThisMonth ? newCustomerThisMonth : 'Loading...' }}</v-chip></p>
                      </div>
                      <div class="flex-container">
                          <p>Active Outlet</p>
                          <p><v-chip class="ma-1" small color="green" dark @click="newOutletDetails(monthNum, year)">{{ activeOutletThisMonth ? activeOutletThisMonth : 'Loading...' }}</v-chip></p>
                      </div>
                      <div class="flex-container">
                          <p>Total Customer Visit</p>
                          <p><v-chip class="ma-1" small color="green" dark @click="newVisitDetails(monthNum, year)">{{ customerVisitThisMonth ? customerVisitThisMonth : 'Loading...' }}</v-chip></p>
                      </div> -->
                  </div>
              </div>
          </div>

          <div class="col-12 col-lg-6">
              <div class="card elevation-5">
                  <div class="card-body">
                      <p class="card-title">Total Order</p>
                      <div id="chartContainer1" style="height: 300px; width: 100%;"></div>
                  </div>
              </div>
          </div>

          <div class="col-12 col-lg-2">
              <div class="card elevation-5">
                  <div class="card-body">
                      <p class="card-title">Category Product</p>
                      <div id="chartContainer2" style="height: 300px; width: 100%;"></div>
                      <!-- <div id="container1" style="height: 300px; width: 100%;"></div> -->
                  </div>
              </div>
          </div>

          <div class="col-12 col-lg-2">
              <div class="card elevation-5">
                  <div class="card-body">
                      <p class="card-title">Commodity External</p>
                      <div id="chartContainer3" style="height: 300px; width: 100%;"></div>
                      <!-- <div id="container2" style="height: 300px; width: 100%;"></div> -->
                  </div>
              </div>
          </div>

          <div class="col-12 col-lg-2">
              <div class="card elevation-5">
                  <div class="card-body">
                      <p class="card-title">Commodity Internal</p>
                      <div id="chartContainer4" style="height: 300px; width: 100%;"></div>
                      <!-- <div id="container3" style="height: 300px; width: 100%;"></div> -->
                  </div>
              </div>
          </div>

      </div>

      <v-dialog v-model="newCustomerDialog" max-width="1024px">
          <div class="card text-white elevation-5">
              <div class="card-body">
                  <v-data-table :headers="newCustomerDetailsHeader" :items="newCustomerDetailsLists" class="elevation-1" :loading="newCustomerDetailsLoading" dense></v-data-table>
              </div>
          </div>
      </v-dialog>

      <v-dialog v-model="newOutletDialog" max-width="1024px">
          <div class="card text-white elevation-5">
              <div class="card-body">
                  <v-data-table :headers="newOutletDetailsHeader" :items="newOutletDetailsLists" class="elevation-1" :loading="newOutletDetailsLoading" dense></v-data-table>
              </div>
          </div>
      </v-dialog>

      <v-dialog v-model="newVisitDialog" max-width="1024px">
          <div class="card text-white elevation-5">
              <div class="card-body">
                  <v-data-table :headers="newVisitDetailsHeader" :items="newVisitDetailsLists" class="elevation-1" :loading="newVisitDetailsLoading" dense></v-data-table>
              </div>
          </div>
      </v-dialog>

  </div>
  
</template>

<script>

export default {
  data() {
      return {
          breadcumbs: [
              {
              text: 'Kencana Ritelindo Indonesia',
              disabled: false,
              href: '/admin/kri',
              },
              {
              text: 'Dashboard',
              disabled: false,
              href: '/admin/kri/dashboard',
              },
              {
              text: 'Overview',
              disabled: true,
              href: 'breadcrumbs_link_2',
              },
          ],
          subYear: '',
          year:'',
          subMonthNum: '',
          monthNum: '',
          subMonthYear: '',
          subMonthYearTotalIDR: '',
          subMonthYearTotalOrder: '',
          subMonthYearTotalWgt: '',
          /*-------------------------------*/
          subMonthSubYear: '',
          subMonthSubYearTotalIDR: '',
          subMonthSubYearTotalOrder: '',
          subMonthSubYearTotalWgt: '',
          /*-------------------------------*/
          monthYear: '',
          monthYearTotalIDR: '',
          monthYearTotalOrder: '',
          monthYearTotalWgt: '',
          /*-------------------------------*/
          monthSubYear: '',
          monthSubYearTotalIDR: '',
          monthSubYearTotalOrder: '',
          monthSubYearTotalWgt: '',
          /*-------------------------------*/
          invSubMonthYear: '',
          invSubMonthYearTotalIDR: '',
          invSubMonthYearTotalOrder: '',
          invSubMonthYearTotalWgt: '',
          /*-------------------------------*/
          invSubMonthSubYear: '',
          invSubMonthSubYearTotalIDR: '',
          invSubMonthSubYearTotalOrder: '',
          invSubMonthSubYearTotalWgt: '',
          /*-------------------------------*/
          invMonthYear: '',
          invMonthYearTotalIDR: '',
          invMonthYearTotalOrder: '',
          invMonthYearTotalWgt: '',
          /*-------------------------------*/
          invMonthSubYear: '',
          invMonthSubYearTotalIDR: '',
          invMonthSubYearTotalOrder: '',
          invMonthSubYearTotalWgt: '',
          /*-------------------------------*/
          orderPercent: '',
          orderPercentSub: '',
          /*-------------------------------*/
          newCustomerSubMonth: '',
          activeOutletSubMonth: '',
          customerVisitSubMonth: '',
          /*-------------------------------*/
          newCustomerThisMonth: '',
          activeOutletThisMonth: '',
          customerVisitThisMonth: '',

          thisMonthThisYear : '',
          lastMonthThisYear : '',
          last2MonthThisYear : '',
          thisMonthLastYear : '',
          lastMonthLastYear : '',
          last2MonthLastYear : '',
        
          orderNow : '',
          countOrderNow : '',
          orderNowYearM1 : '',
          countOrderNowYearM1 : '',
          orderMonthM1 : '',
          countOrderMonthM1 : '',
          orderMonthM1YearM1 : '',
          countOrderMonthM1YearM1 : '',
          orderMonthM2 : '',
          countOrderMonthM2 : '',
          orderMonthM2YearM1 : '',
          countOrderMonthM2YearM1 : '',

          visitTotalNow : '',
          visitAplikatorNow : '',
          visitUmumNow : '',
          visitTokoNow : '',
          visitOnlineNow : '',
          visitOfflineNow : '',
          visitTotalMonthM1 : '',
          visitAplikatorMonthM1 : '',
          visitUmumMonthM1 : '',
          visitTokoMonthM1 : '',
          visitOnlineMonthM1 : '',
          visitOfflineMonthM1 : '',
          visitTotalMonthM2 : '',
          visitAplikatorMonthM2 : '',
          visitUmumMonthM2 : '',
          visitTokoMonthM2 : '',
          visitOnlineMonthM2 : '',
          visitOfflineMonthM2 : '',

          showDataKategori: true,
          showDataKomoditiEksternal: true,
          showDataKomoditiInternal: true,

          userGroup: null,
          appl_id: 'DASHBOARD',
          newCustomerDialog: false,
          newCustomerDetailsHeader: [
              { text: 'OFFICE', value: 'office', align: 'left', class: 'primary--text blue lighten-5' },
              { text: 'SALESMAN', value: 'NamaSales', align: 'left', class: 'primary--text blue lighten-5' },
              { text: 'CUSTOMER', value: 'count_customer', align: 'right', class: 'primary--text blue lighten-5' },
          ],
          newCustomerDetailsLists: [],
          newCustomerDetailsLoading: false,
          newOutletDialog: false,
          newOutletDetailsHeader: [
              { text: 'OFFICE', value: 'office', align: 'left', class: 'primary--text blue lighten-5' },
              { text: 'SALESMAN', value: 'salesman_name', align: 'left', class: 'primary--text blue lighten-5' },
              { text: 'CUSTOMER', value: 'total', align: 'right', class: 'primary--text blue lighten-5' },
          ],
          newOutletDetailsLists: [],
          newOutletDetailsLoading: false,
          newVisitDialog: false,
          newVisitDetailsHeader: [
              { text: 'OFFICE', value: 'office', align: 'left', class: 'primary--text blue lighten-5' },
              { text: 'SALESMAN', value: 'NamaSales', align: 'left', class: 'primary--text blue lighten-5' },
              { text: 'CUSTOMER', value: 'total', align: 'right', class: 'primary--text blue lighten-5' },
          ],
          newVisitDetailsLists: [],
          newVisitDetailsLoading: false
      }
  },
  async mounted(){
      
      this.initialize()

  },
  methods:{

      async initialize(){

          this.$store.dispatch('setOverlay', true)

          /*await axios.get(`${process.env.VUE_APP_URL}/api/kmb/dashboard/getDashboardOrderChart1`, { 
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
              
              this.renderChart1(res.data.data2, res.data.data1)

          })
          .catch(err => {

              this.$store.dispatch('setOverlay', false)
              if(err.response.status == '401'){
                  this.$router.push('/');
              }

          })

          await axios.get(`${process.env.VUE_APP_URL}/api/kmb/dashboard/getDashboardOrderChart2`, { 
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {

              this.renderChart2(res.data.data)

          })
          .catch(err => {

              this.$store.dispatch('setOverlay', false)
              if(err.response.status == '401'){
                  this.$router.push('/');
              }

          })

          await axios.get(`${process.env.VUE_APP_URL}/api/kmb/dashboard/getDashboardOrderChart3`, { 
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
              
              this.renderChart3(res.data.data)

          })
          .catch(err => {

              this.$store.dispatch('setOverlay', false)
              if(err.response.status == '401'){
                  this.$router.push('/');
              }

          })

          await axios.get(`${process.env.VUE_APP_URL}/api/kmb/dashboard/getDashboardOrderChart4`, { 
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
              
              this.renderChart4(res.data.data)

          })
          .catch(err => {

              this.$store.dispatch('setOverlay', false)
              if(err.response.status == '401'){
                  this.$router.push('/');
              }

          })*/

          await axios.get(`${process.env.VUE_APP_URL}/api/kri/dashboard/getDashboardItems`, { 
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
              
              this.subYear = res.data.subYear
              this.year = res.data.year
              this.subMonthNum = res.data.subMonthNum
              this.monthNum = res.data.monthNum

              this.thisMonthThisYear = res.data.thisMonthThisYear
              this.lastMonthThisYear = res.data.lastMonthThisYear
              this.last2MonthThisYear = res.data.last2MonthThisYear
              this.thisMonthLastYear = res.data.thisMonthLastYear
              this.lastMonthLastYear = res.data.lastMonthLastYear
              this.last2MonthLastYear = res.data.last2MonthLastYear
              
              this.orderNow = res.data.orderNow
              this.countOrderNow = res.data.countOrderNow
              this.orderNowYearM1 = res.data.orderNowYearM1
              this.countOrderNowYearM1 = res.data.countOrderNowYearM1
              this.orderMonthM1 = res.data.orderMonthM1
              this.countOrderMonthM1 = res.data.countOrderMonthM1
              this.orderMonthM1YearM1 = res.data.orderMonthM1YearM1
              this.countOrderMonthM1YearM1 = res.data.countOrderMonthM1YearM1
              this.orderMonthM2 = res.data.orderMonthM2
              this.countOrderMonthM2 = res.data.countOrderMonthM2
              this.orderMonthM2YearM1 = res.data.orderMonthM2YearM1
              this.countOrderMonthM2YearM1 = res.data.countOrderMonthM2YearM1
            //   this. = res.data.

              this.visitTotalNow = res.data.visitTotalNow
              this.visitAplikatorNow = res.data.visitAplikatorNow
              this.visitUmumNow = res.data.visitUmumNow
              this.visitTokoNow = res.data.visitTokoNow
              this.visitOnlineNow = res.data.visitOnlineNow
              this.visitOfflineNow = res.data.visitOfflineNow
              this.visitTotalMonthM1 = res.data.visitTotalMonthM1
              this.visitAplikatorMonthM1 = res.data.visitAplikatorMonthM1
              this.visitUmumMonthM1 = res.data.visitUmumMonthM1
              this.visitTokoMonthM1 = res.data.visitTokoMonthM1
              this.visitOnlineMonthM1 = res.data.visitOnlineMonthM1
              this.visitOfflineMonthM1 = res.data.visitOfflineMonthM1
              this.visitTotalMonthM2 = res.data.visitTotalMonthM2
              this.visitAplikatorMonthM2 = res.data.visitAplikatorMonthM2
              this.visitUmumMonthM2 = res.data.visitUmumMonthM2
              this.visitTokoMonthM2 = res.data.visitTokoMonthM2
              this.visitOnlineMonthM2 = res.data.visitOnlineMonthM2
              this.visitOfflineMonthM2 = res.data.visitOfflineMonthM2

              this.$store.dispatch('setOverlay', false)

          })
          .catch(err => {

              this.$store.dispatch('setOverlay', false)
              if(err.response.status == '401'){
                  this.$router.push('/');
              }

          })

          await axios.get(`${process.env.VUE_APP_URL}/api/kri/dashboard/getShowChart`, { 
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
            console.log(res.data.data.commodity);
            
            this.renderChart1(res.data.data.sale, res.data.data.retur)
            this.renderChart2(res.data.data.commodity)
            this.renderChart3(res.data.data.commodity_ext)
            this.renderChart4(res.data.data.commodity_int)

            // let datas = res.data.data
            // let comms = datas.commodity
            // comms.forEach(item => {
            //     item.y = parseInt(parseFloat(item.y).toFixed(2));
            // });
            // let commExt = datas.commodity_ext
            // commExt.forEach(item => {
            //     item.y = parseInt(parseFloat(item.y).toFixed(2));
            // });
            // let commInt = datas.commodity_int
            // commInt.forEach(item => {
            //     item.y = parseInt(parseFloat(item.y).toFixed(2));
            // });
            // let sales = datas.sale
            // sales.forEach(item => {
            //         item.x = parseInt(item.label)
            //         item.y = Math.floor(((item.y /1000000) * 100) / 100)
            // });
            // let retur = datas.retur
            // retur.forEach(item => {
            //     item.x = parseInt(item.label)
            //     item.y = Math.floor(((item.y /1000000) * 100) / 100)
            // });
            // console.log(comms);
            
            // var labelNoData = "No Data Found!"
            // this.showDataKategori = false            
            // this.getPieChart(comms, 'container1', 'by Category', '', labelNoData)
            // this.showDataKomoditiEksternal = false
            // this.getPieChart(commExt, 'container2', 'Commodity on External Category', '', labelNoData)
            // this.showDataKomoditiInternal = false
            // this.getPieChart(commInt, 'container3', 'Commodity on Internal Category', '', labelNoData)
            
            // const data1 = {
            //     type: "column",
            //     click: this.onClick1,
            //     name: "Total Sales",
            //     indexLabel: "{y}",
            //     indexLabelFontSize: 14,
            //     showInLegend: true,
            //     indexLabelPlacement: "outside",  
            //     indexLabelOrientation: "horizontal",
            // }
            // const data2 = {
            //     type: "column",
            //     click: this.onClick2,
            //     name: "Total Retur",
            //     indexLabel: "{y}",
            //     indexLabelFontSize: 14,
            //     showInLegend: true,
            //     indexLabelPlacement: "outside",  
            //     indexLabelOrientation: "horizontal",
            // }
            // this.getChart(sales,retur, 'container5', 'Sales', '', data1, data2);

            
            this.$store.dispatch('setOverlay', false)

          })
          .catch(err => {

              this.$store.dispatch('setOverlay', false)
              if(err.response.status == '401'){
                  this.$router.push('/');
              }

          })
          
        //   await axios.post(`${process.env.VUE_APP_URL}/api/kri/sales/salesAnalysisV2/sum-pie`, { 
        //             // date_from: "202311",
        //             // date_to: "202411",
        //             office_id: "",
        //             period_type: "Month",
        //             region: "",
        //             subregion: "",
        //     },{
        //             headers: {
        //                 'Accept': 'application/json',
        //                 'Content-Type': 'application/json',
        //                 'Access-Control-Allow-Origin': '*',
        //                 Authorization: `Bearer ${this.$store.getters.isTokenUser}`
        //             } 
        //     })
        //     .then(res => {
              
        //        this.renderChart1(res.data.data.sale, res.data.data.retur)
        //        this.renderChart2(res.data.data.commodity)
        //        this.renderChart3(res.data.data.commodity_ext)
        //        this.renderChart4(res.data.data.commodity_int)

        //     //    var labelNoData = "No Data Found!"
        //     //    this.showDataKategori = false
        //     //    this.getPieChart(res.data.data.commodity, 'container2', 'by Category', '', labelNoData)

               
               
        //     })
        //     .catch(err => {

        //         this.$store.dispatch('setOverlay', false)
        //         if(err.response.status == '401'){
        //             this.$router.push('/');
        //         }

        //     })

      },

      getPieChart(dt_detail, container_detail, title_detail, label_detail, labelNoData){
        var pie_chart = new CanvasJS.Chart(container_detail, {
            animationEnabled: true,
            exportEnabled: true,
            theme: "light2",
            exportEnabled: true,
            title: {
                text: title_detail,
                fontFamily: "Calibri",
                fontSize: 20
            },
            exportEnabled: true,
            subtitles:[
                {
                    text: label_detail,
                    //Uncomment properties below to see how they behave
                    fontFamily: "Calibri",
                    fontColor: "red",
                    fontSize: 12
                }
            ],
            legend: {
                itemclick: this.toggleDataPointVisibility
            },
            data: [{

                type: "pie",
                percentFormatString: "#0.##",
                indexLabel: "{label} #percent%",
                indexLabelFontSize: 12,

            }]
        });
        pie_chart.options.data[0].dataPoints = dt_detail;
        // this.showDefaultText(pie_chart, "No Data Found!");
        this.showDefaultText(pie_chart, labelNoData);

        if (this.x.matches) {

            for(var i = 0; i < pie_chart.options.data.length; i++){
                pie_chart.options.data[i].indexLabelFontSize = 6;
            }
            pie_chart.render();
        }
        pie_chart.render();
        },
      renderChart1(data1, data2){

          var chart1 = new CanvasJS.Chart("chartContainer1", {
                  animationEnabled: true,
                  theme: "light2",
                  axisY: {
                      title: "IDR",
                      titleFontSize: 24
                  },
                  data: [{
                      type: "column",
                      indexLabel: "{y}",
                      name: "Order",
                      showInLegend: true,
                      indexLabelPlacement: "inside",  
                      indexLabelOrientation: "vertical",
                      color: "#07dbf7",
                      yValueFormatString: "###,###",
                      dataPoints: data1,
                  },
                  {
                      type: "column",
                      indexLabel: "{y}",
                      name: "Retur",
                      showInLegend: true,
                      indexLabelPlacement: "inside",  
                      indexLabelOrientation: "vertical",
                      color: "#f79b07",
                      yValueFormatString: "###,###",
                      dataPoints: data2,
                  }]
              })
          
          chart1.render()

      },

      renderChart2(data){

          var chart2 = new CanvasJS.Chart("chartContainer2", {
              animationEnabled: true,
              exportEnabled: false,
              theme: "light2",
              data: [{
                  type: "pie",
                  yValueFormatString: "##0.00\"%\"",
                  dataPoints: data,
                  }]
          })

          chart2.render()

      },

      renderChart3(data){

          var chart3 = new CanvasJS.Chart("chartContainer3", {
              animationEnabled: true,
              exportEnabled: false,
              theme: "light2",
              data: [{
                  type: "pie",
                  yValueFormatString: "##0.00\"%\"",
                  dataPoints: data,
                  }]
          })

          chart3.render()

      },

      renderChart4(data){

          var chart4 = new CanvasJS.Chart("chartContainer4", {
              animationEnabled: true,
              exportEnabled: false,
              theme: "light2",
              data: [{
                  type: "pie",
                  yValueFormatString: "##0.00\"%\"",
                  dataPoints: data,
                  }]
          })

          chart4.render()

      },

      async newCustomerDetails(month, year){

          this.newCustomerDetailsLists = []
          this.newCustomerDialog = true
          this.newCustomerDetailsLoading = true

          await axios.get(`${process.env.VUE_APP_URL}/api/kmb/dashboard/getNewCustomerTotalDetails?month=${month}&year=${year}`, { 
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {

              this.newCustomerDetailsLoading = false
              this.newCustomerDetailsLists = res.data.newCustomerDetails

          })
          .catch(err => {

              this.$store.dispatch('setOverlay', false)
              if(err.response.status == '401'){
                  this.$router.push('/');
              }

          })

      },

      async newOutletDetails(month, year){

          this.newOutletDetailsLists = []
          this.newOutletDialog = true
          this.newOutletDetailsLoading = true

          await axios.get(`${process.env.VUE_APP_URL}/api/kmb/dashboard/getNewOutletTotalDetails?month=${month}&year=${year}`, { 
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {

              this.newOutletDetailsLoading = false
              this.newOutletDetailsLists = res.data.newOutletDetails

          })
          .catch(err => {

              this.$store.dispatch('setOverlay', false)
              if(err.response.status == '401'){
                  this.$router.push('/');
              }

          })

      },

      async newVisitDetails(month, year){

          this.newVisitDetailsLists = []
          this.newVisitDialog = true
          this.newVisitDetailsLoading = true

          await axios.get(`${process.env.VUE_APP_URL}/api/kmb/dashboard/getNewVisitTotalDetails?month=${month}&year=${year}`, { 
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {

              this.newVisitDetailsLoading = false
              this.newVisitDetailsLists = res.data.newVisitDetails

          })
          .catch(err => {

              this.$store.dispatch('setOverlay', false)
              if(err.response.status == '401'){
                  this.$router.push('/');
              }

          })

      }

  },
  computed: {
    formattedOrderMonthM1() {
      return this.orderMonthM1 ? new Intl.NumberFormat('id-ID', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.orderMonthM1) : 'Loading...';
    }
  }
}
</script>

<style scoped>

  .flex-container {
      display: flex;
      justify-content: space-between;
  }

  .v-breadcrumbs {
      padding: 5px 5px !important;
  }

  .container{
      margin-bottom: 60px !important;
  }

  .v-application p {
      margin-bottom: 0px !important;
  }

  .v-text-field {
      padding-top: 0px !important;
  }

  .v-breadcrumbs {
      padding: 5px 5px !important;
  }

  .badgeInfo {
      font-size: 1rem;
  }

  .bgCustom {
      background: #a2d2ff !important;
      color: #fff;
  }

  .bgCustom2 {
      background: #bde0fe !important;
      color: #fff;
  }

  .bgCustom3 {
      background: rgba(19, 64, 116, 0.5);
      color: #fff;
  }

  .container{
      margin-bottom: 60px !important;
  }

</style>